import {createRouter, createWebHistory} from "vue-router"

const nemus = [
    {
        path: "/",
        redirect: "/article/manage"
    },
    {
        path: "/login",
        component: () => import("../pages/AdminLogin.vue")
    },
    {
        path: "/article/manage",
        component: () => import("../pages/ArticleManage.vue")
    },
    {
        path: "/article/public",
        component: () => import('../pages/ArticlePublic.vue')
    },
    {
        path: "/album/association_article",
        component: () => import('../pages/AlbumAssociationArticle.vue')
    },
    {
        path: "/article/comment/manage",
        component: () => import('../pages/ArticleCommentManage.vue')
    },
    {
        path: "/article/category/manage",
        component: () => import('../pages/ArticleCategoryManage.vue')
    },
    {
        path: "/album/manage",
        component: () => import("../pages/AlbumManage.vue")
    },
    {
        path: "/album/public",
        component: () => import("../pages/AlbumPublic.vue")
    },
    {
        path: "/ebook/public",
        component: () => import("../pages/BookPublic.vue")
    },
    {
        path: "/ebook/manage",
        component: () => import("../pages/BookManage.vue")
    },
    {
        path: "/ebook/chapter/manage",
        component: () => import("../pages/BookChapterManage.vue")
    },
    {
        path: "/subscribe/manage",
        component: () => import("../pages/SubscribeManage.vue")
    },
    {
        path: "/resource/manage",
        component: () => import("../pages/ResourceManage.vue")
    },
    {
        path: "/video/manage",
        component: () => import("../pages/VideoManage.vue")
    },
    {
        path: "/video/public",
        component: () => import("../pages/VideoPublic.vue")
    }
]

const Router = createRouter({
    history: createWebHistory(),
    routes: nemus
})

export default Router