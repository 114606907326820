<template>
  <el-menu :default-active="currentPath"
           :unique-opened="true"
           active-text-color="#ffd04b"
           background-color="#21292C"
           text-color="#fff"
           style="height: 100%"
           router>
    <div style="display: flex; justify-content: center;padding: 10px;">
      <img :src="logo" alt="logo" style="height: 40px"/>
      <p style="text-align: center;color:white;margin-left: 10px;">
        <strong>博客管理后台</strong>
      </p>
    </div>
    <el-sub-menu index="/article">
      <template #title>
        <el-icon>
          <Document/>
        </el-icon>
        文章
      </template>
      <el-menu-item index="/article/public">
        <span>发布文章</span>
      </el-menu-item>
      <el-menu-item index="/article/manage">
        <span>文章管理</span>
      </el-menu-item>
      <el-menu-item index="/article/comment/manage">
        <span>评论管理</span>
      </el-menu-item>
      <el-menu-item index="/article/category/manage">
        <span>分类管理</span>
      </el-menu-item>
    </el-sub-menu>

    <el-sub-menu index="/video">
      <template #title>
        <el-icon>
          <VideoCamera/>
        </el-icon>
        视频
      </template>
      <el-menu-item index="/video/public">
        <span>发布视频</span>
      </el-menu-item>
      <el-menu-item index="/video/manage">
        <span>视频管理</span>
      </el-menu-item>
    </el-sub-menu>

    <el-sub-menu index="/album">
      <template #title>
        <el-icon>
          <Notification/>
        </el-icon>
        专栏
      </template>
      <el-menu-item index="/album/public">
        <span>发布专栏</span>
      </el-menu-item>
      <el-menu-item index="/album/manage">
        <span>专栏管理</span>
      </el-menu-item>
    </el-sub-menu>

    <el-sub-menu index="/ebook">
      <template #title>
        <el-icon>
          <Notebook/>
        </el-icon>
        电子书
      </template>
      <el-menu-item index="/ebook/public">
        <span>发布电子书</span>
      </el-menu-item>
      <el-menu-item index="/ebook/manage">
        <span>电子书管理</span>
      </el-menu-item>
    </el-sub-menu>

    <el-sub-menu index="/subscribe">
      <template #title>
        <el-icon>
          <Notification/>
        </el-icon>
        订阅
      </template>
      <el-menu-item index="/subscribe/manage">
        <span>订阅管理</span>
      </el-menu-item>
    </el-sub-menu>

    <el-sub-menu index="/resource">
      <template #title>
        <el-icon>
          <Reading/>
        </el-icon>
        资源
      </template>
      <el-menu-item index="/resource/manage">
        <span>资源管理</span>
      </el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<script setup>
import {onMounted, ref, watch, watchEffect} from 'vue';
import {useRoute} from 'vue-router';
import logo from "@/assets/logo.png";
import {VideoCamera} from "@element-plus/icons-vue";

const route = useRoute();
const currentPath = ref('')

onMounted(() => {
  currentPath.value = route.path;
})

watch(() => route.path, (newPath) => {
  currentPath.value = newPath;
});

watchEffect(() => {
  currentPath.value = route.path
})
</script>