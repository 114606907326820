const store = {
    setUserInfo(value) {
        localStorage.setItem("userInfo", JSON.stringify(value))
    },
    setToken(value) {
        localStorage.setItem("userToken", value)
    },
    getToken() {
        let userToken = localStorage.getItem("userToken")
        if (userToken == null) {
            return ""
        }
        return userToken
    },
    getUserInfo() {
        let userInfo = localStorage.getItem("userInfo")
        if (userInfo == null || userInfo === "") {
            return null
        }
        try {
            return JSON.parse(userInfo)
        } catch (ee) {
            console.log(ee)
            localStorage.clear()
            return null
        }
    },
    clear() {
        localStorage.clear()
    }
}
export default store