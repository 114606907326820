<template>
  <div class="footer-div">
    <div>
      <span class="copyright">Copyright &copy; 2024 wujiuye.com. All rights reserved.</span>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.footer-div {
  text-align: center;
}

.footer-div a {
  font-size: 14px;
  text-decoration: none;
  color: rgb(128, 128, 128);
}

.copyright {
  font-size: 14px;
  color: rgb(128, 128, 128);
  margin-left: 20px;
}
</style>
