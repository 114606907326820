<template>
  <div class="header-box">
    <div class="header-content" v-if="user!=null">
      <span>管理员：</span>
      <span>{{ user == null ? "" : user.username }}</span>
      <el-button type="primary" style="margin-left: 20px;" @click="logout()">退出</el-button>
    </div>
  </div>
</template>

<script setup>
import store from "@/utils/store";

const user = store.getUserInfo()

const logout = () => {
  store.clear()
  window.location.href = "/login"
}

</script>

<style>
.header-box {
  background: #3E81B3;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-content {
  padding-right: 30px;
  text-align: center;
}
</style>