import {createApp} from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Router from './router'
import App from './App.vue'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import store from "@/utils/store";

Router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next();
    } else {
        const token = store.getToken();
        if (token === null || token === '') {
            next('/login');
        } else {
            next();
        }
    }
});

const app = createApp(App)
app.use(ElementPlus)
app.use(Router)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')