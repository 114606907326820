<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="200px" v-if="store.getToken()!==''">
        <AppMenu/>
      </el-aside>
      <el-container style="height: 100vh;">
        <el-header height="48px" style="padding: 0;margin: 0;" v-if="store.getToken()!==''">
          <AppHeader/>
        </el-header>
        <el-main>
          <router-view/>
        </el-main>
        <el-divider/>
        <el-footer height="35px" style="padding: 0;margin: 0;">
          <AppFooter/>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import AppHeader from "@/components/AppHeader.vue"
import AppMenu from "@/components/AppMenu.vue"
import AppFooter from "./components/AppFooter.vue";
import store from "@/utils/store";

// ========= start ==> 解决错误：ResizeObserver loop completed with undelivered notifications
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = this;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};
// ========= end

</script>